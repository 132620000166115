export default {
  en: {
    common: {
      emission: 'Emission costs',
      share: 'bond | bonds',
      myInterestRate: 'My returns',
      dividend: 'Interest payment',
      myDividend: 'Mijn interest amount',
      nominalAmount: 'Nominal amount per bond',
      nominalAmountAlternative: 'Drawn amount per bond',
      netDividendYield: 'Returns',
      netDividendYieldMine: 'My interest',
      perAnnum: 'per annum',
      bond: 'bond | bonds',
      fundCompletedLong: 'No enrollment at this moment',
    },
    dashboard: {
      funds: {
        otherProjects: 'Investment vehicles',
        myProjects: 'My Investment vehicle',
        fullHistory: 'Full History',
        myInvestment: 'My Bonds',
        fullHistoryDividends: 'See full history of your interest',
        progress: 'Availability',
        premium: 'Login to view the information of this investment vehicle',
        noInvestorStatus: 'Information only available for investors',
      },
      dividendHistory: {
        allNotesInterest: 'Interest Amount',
        ANotesInterest: 'Additional compensation',
        CNotesInterest: 'Variable interest',
        errorEarning: 'Something went wrong! If you think this is an error please contact us',
        noEarnings: 'No earnings',
      },
      balance: {
        totalBalance: 'My investment vehicle',
      },
    },
    tooltip: {
      availability: 'Available amount of notes',
      nominalAmount: 'My dummy text',
      investmentHistory: 'The history of your investments in this investment vehicle',
      dividendHistory: 'History of interest payments in this investment vehicle',
      netDividendYield: 'The interest earned by the investment vehicle from the start of the investment vehicle (note: may deviate from own return, depending on the entry date).',
      netDividendYieldMine: 'Total amount received from interest payments ',
      amountGet: 'Total amount received from interest payments ',
      yourInvestment: 'Percentage of the total number of Notes you own',
      dealSize: 'Total loan size',
    },
    checkout: {
      investment: {
        availableFund: 'The availability bar shows the availability of the investment vehicle',
      },
      status: {
        congratulations: 'Congratulations with purchasing your <strong>{amount} Notes</strong> in the <strong>{assetName}</strong>',
      },
    },
    identification: {
      business: {
        ubo: 'UBO Statement',
        organigram: 'Drawn organization chart',
      },
    },
  },
  nl: {
    common: {
      emission: 'Emissie kosten',
      share: 'obligaties | obligaties',
      myInterestRate: 'Mijn opbrengsten',
      dividend: 'Couponrente',
      myDividend: 'Mijn rente | Mijn rente',
      nominalAmount: 'Nominaal bedrag per obligatie',
      nominalAmountAlternative: 'Opgenomen bedrag per obligatie',
      netDividendYield: 'Rendement ',
      netDividendYieldMine: 'Mijn rente',
      perAnnum: 'per annum',
      bond: 'obligaties | obligaties',
      fundCompletedLong: 'Geen inschrijvingen op dit moment',
    },
    dashboard: {
      funds: {
        otherProjects: 'Vehikels',
        myProjects: 'Mijn vehikels',
        fullHistory: 'Bekijk de volledige historie van jouw investeringen',
        myInvestment: 'Mijn Obligaties',
        fullHistoryDividends: 'Bekijk de volledige historie van jouw couponrente',
        premium: 'Login om de fondsinformatie te zien',
        noInvestorStatus: 'Informatie alleen beschikbaar voor investeerders',
      },
      dividendHistory: {
        allNotesInterest: 'Couponrente',
        ANotesInterest: 'Additionele vergoeding',
        CNotesInterest: 'Variabele Rente',
        errorEarning: 'Er is iets fout gegaan! Als u denkt dat dit een fout is, neemt u contact met ons op',
        noEarnings: 'Geen rente ',
      },
      balance: {
        totalBalance: ' Mijn investering',
      },
    },
    tooltip: {
      availability: 'Beschikbaar aantal Notes',
      nominalAmount: 'Nominaal bedrag per Note',
      investmentHistory: 'De historie van jouw investeringen in dit vehikels',
      dividendHistory: 'De historie van de rente betalingen in het vehikels',
      netDividendYield: 'De rente behaald door het vehikels vanaf de start van het vehikels (let op: kan afwijken van eigen rendement, afhankelijk van de instapdatum)',
      netDividendYieldMine: 'Totale bedrag ontvangen uit rente betalingen',
      amountGet: 'Totale bedrag ontvangen uit rente betalingen',
      yourInvestment: 'Percentage van het totaal aantal Notes dat u in bezit heeft',
      dealSize: 'Totale leningsom',
    },
    checkout: {
      status: {
        congratulations: 'Gefeliciteerd met de aankoop van uw <strong> {amount} Notes </strong> in  <strong>{assetName} </strong>',
      },
    },
    identification: {
      business: {
        ubo: 'UBO verklaring',
        organigram: 'Getekend organigram',
      },
    },
  },
};
